<template>
  <v-main>
    <v-card color="transparent" flat>
      <h1 class="text-center">Page not found</h1>
      <p class="grey--text lighten-5 text-center">
        The page you are trying to get is not exist or you have no access to it
      </p>
    </v-card>
  </v-main>
</template>
  
<script>

export default {
    name: 'NotFound'
}

</script>
